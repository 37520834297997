@import 'app.scss';
.wrapper {
  --content-padding: 0;

  align-items: center;

  @include from-breakpoint(md) {
    --content-padding: #{spacing(6)};
  }

  @include from-breakpoint(lg) {
    --content-padding: #{spacing(12)};
  }
}

.content {
  grid-column: 1 / -1;
  margin-bottom: 0;
  padding-right: calc(var(--content-padding) * 2);
  padding-left: var(--content-padding);

  @include from-breakpoint(md) {
    grid-column: 1 / span 6;
  }
}

.media {
  grid-column: 1 / -1;
  margin-top: spacing(6);

  @include from-breakpoint(md) {
    grid-column: 7 / span 6;
    margin-top: 0;
  }
}

.textRight {
  @include from-breakpoint(md) {
    .content {
      grid-column: 7 / span 6;
    }

    .media {
      grid-row: 1;
      grid-column: 1 / span 6;
    }
  }
}

.inStore {
  .content {
    grid-column: 2 / span 5;
    padding-right: 20px;
    padding-left: 0;
  }

  .media {
    grid-column: 7 / span 5;
  }

  &.textRight {
    .content {
      grid-column: 7 / span 5;
      padding-right: 0;
      padding-left: 20px;
    }

    .media {
      grid-column: 2 / span 5;
    }
  }
}
