@import 'app.scss';
.figure {
  position: relative;
  aspect-ratio: 640 / 410;
}

.image {
  border-radius: var(--border-radius-base);
}

.keepAspectRatio {
  aspect-ratio: unset;
}
