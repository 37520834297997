@import 'app.scss';
.figure {
  position: relative;
  margin-bottom: 0;

  &:hover {
    .author {
      opacity: 1;
    }
  }
}

.author {
  position: absolute;
  bottom: spacing(4);
  left: spacing(4);
  transition: opacity var(--transition-base);
  opacity: 0;
}
